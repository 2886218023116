import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_posts = _resolveComponent("posts")!
  const _component_loader = _resolveComponent("loader")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_posts, {
          key: 0,
          posts: _ctx.posts
        }, null, 8, ["posts"]))
      : (_openBlock(), _createBlock(_component_loader, { key: 1 }))
  ]))
}