
import { defineComponent } from "vue";
import { getBlogPosts, checkAndAddAltTags } from "@/utils/blog";
import Posts from "@/components/Blog/Posts.vue";
import Loader from "@/components/common/Loader.vue";

export default defineComponent({
  components: {
    Posts,
    Loader,
  },

  data() {
    return {
      isLoading: true,
      posts: [],
    };
  },

  methods: {
    fetchPosts() {
      this.isLoading = true;
      getBlogPosts().then((posts) => {
        this.posts = posts;
        checkAndAddAltTags();
        this.isLoading = false;
      });
    },
  },

  created() {
    this.fetchPosts();
  },
});
